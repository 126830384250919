import { gql } from "@apollo/client";

export const PRODUCT_FRAGMENT = gql`
  fragment product on Product {
    name
    type
    slug
    description {
      json
    }
    imagesCollection {
      items {
        url
      }
    }
  }
`;
