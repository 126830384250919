import styled from "styled-components";
import { Link } from "gatsby";
import Logo from "../navigation/logo";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  background: ${({ theme }) => theme.gray3};
`;

export const Left = styled.div`
  display: flex;
  padding: 20px;
  text-align: center;
  @media ${({ theme }) => theme.medium} {
    padding: 0;
    text-align: left;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: center;
  padding: 20px 40px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  @media ${({ theme }) => theme.medium} {
    padding: 0;
    flex-direction: row;
    width: initial;
    margin-right: -20px;
    margin-left: -20px;
  }
`;

export const MenuItemDiv = styled.div`
  display: flex;
  padding: 10px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.2px;
  @media ${({ theme }) => theme.medium} {
    padding: 20px;
  }
`;
export const MenuItem = styled(Link)`
  display: flex;
  padding: 10px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.2px;
  @media ${({ theme }) => theme.medium} {
    padding: 20px;
  }
`;

export const LogoLink = styled(Logo)`
  width: 40px;
  height: 40px;
`;
