import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 600px;
  width: 100%;
  .gatsby-image-wrapper {
    height: 100%;
  }
`;
