import { createGlobalStyle } from "styled-components";
import KalamWoff from "./kalam-v11-latin-regular.woff";
import KalamWoff2 from "./kalam-v11-latin-regular.woff2";
import PoppinsWoff from "./poppins-v15-latin-regular.woff";
import PoppinsWoff2 from "./poppins-v15-latin-regular.woff2";

export default createGlobalStyle`
@font-face {
        font-family: 'Kalam';
        font-style: normal;
        font-weight: 400;
        src: local(''),
            url(${KalamWoff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
            url(${KalamWoff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
    @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        src: local(''),
            url(${PoppinsWoff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
            url(${PoppinsWoff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
    `;
