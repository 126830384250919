import { useQuery, gql } from "@apollo/client";
import { useStaticQuery, graphql } from "gatsby";

const useFooter = () => {
  if (process.env.GATSBY_CONTENTFUL_HOST) {
    const { data } = useQuery(gql`
      query contentfulFooterText {
        footerTextCollection(preview: true) {
          items {
            footerText
          }
        }
      }
    `);
    return data?.footerTextCollection?.items[0]?.footerText;
  }
  const ssrData = useStaticQuery(graphql`
    query gatsbyFooterText {
      allContentfulFusszeile {
        nodes {
          footerText
        }
      }
    }
  `);

  return ssrData?.allContentfulFusszeile?.nodes[0]?.footerText;
};
export default useFooter;
