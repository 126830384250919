import React from 'react'
import * as Foot from './styled';
import useFooter from '../../hooks/useFooter'

const Footer = () => {
    const text = useFooter()
    return (
        <Foot.Wrapper>
            <Foot.Left>{text}</Foot.Left>
            <Foot.Menu>
                <Foot.LogoLink secondary />
                <Foot.MenuItemDiv>vonThea {new Date().getFullYear()}</Foot.MenuItemDiv>
                <Foot.MenuItem to="/ueber-mich">Über Mich</Foot.MenuItem>
                <Foot.MenuItem to="/">Produkte</Foot.MenuItem>
                <Foot.MenuItem to="/impressum">Impressum</Foot.MenuItem>
                <Foot.MenuItem to="/datenschutzerklaerung">Datenschutz</Foot.MenuItem>
            </Foot.Menu>
        </Foot.Wrapper>
    )
}

export default Footer
