import React from 'react'
import theme from '../../theme/theme'
import { ThemeProvider } from "styled-components"
import Navigation from '../navigation'
import Footer from '../footer'
import * as Wrapp from './styled'
import Fonts from '../../theme/fonts';
import GlobalStyles from '../../theme/globalStyles';
import { Helmet } from 'react-helmet'

const Wrapper = ({ children, image = undefined, title = "" }) => {
   
    return (
        <ThemeProvider theme={theme}>
            <Helmet title={title ? title : 'vonThea'} />

            <Fonts />
            <GlobalStyles />

            <Wrapp.Outer>
                <Navigation image={image} />

                <Wrapp.Content>
                    {children}
                </Wrapp.Content>
                <Footer />

            </Wrapp.Outer>

        </ThemeProvider>
    )
}

export default Wrapper
