import { gql } from "@apollo/client";

export const CONTENTFUL_CONTACT = gql`
  query contentfulContact {
    contactCollection(preview: true) {
      items {
        kontakt{
          json
        }
      }
    }
  }
`;
