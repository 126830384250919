import { useQuery } from "@apollo/client";
import { useStaticQuery, graphql } from "gatsby";
import { CONTENTFUL_CONTACT } from "../queries/contentful/contact";

const useContact = () => {
  if (process.env.GATSBY_CONTENTFUL_HOST) {
    const { data } = useQuery(CONTENTFUL_CONTACT);
    return data?.contactCollection?.items[0]?.kontakt?.json;
  }
  const ssrData = useStaticQuery(graphql`
    query gatsbyContact {
      allContentfulKontakt {
        nodes {
          kontakt {
            raw
          }
        }
      }
    }
  `);

  return JSON.parse(ssrData?.allContentfulKontakt?.nodes[0]?.kontakt.raw);
};
export default useContact;
