export const contentfulProductsMapper = (data) => {
  const serializeProduct = (item) => {
    return {
      ...item,
      ...{
        images: item.imagesCollection.items.map((image) => {
          return {
            fluid: {
              src: image.url,
            },
          };
        }),
      },
    };
  };
  if (Array.isArray(data?.productCollection?.items)) {
    return data?.productCollection?.items.map((item) => serializeProduct(item));
  }
  if (data?.product) {
    return serializeProduct(data.product);
  }
};
