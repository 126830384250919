import React from 'react'
import Image from "gatsby-image";
import { Wrapper } from './styled'
const HeaderImage = ({ image }) => {    
    return (
        <Wrapper>
            <Image fluid={image.fluid} />
        </Wrapper>
    )
}

export default HeaderImage
