import styled from "styled-components";

export const Outer = styled.div`
  @media ${({ theme }) => theme.medium} {
    padding: 20px 20px 0 20px;
  }
`;

export const Content = styled.div`
  height: 100%;
  padding: 0 20px;
  @media ${({ theme }) => theme.medium} {
    padding: 0 60px;
  }
  background-color: ${({ theme }) => theme.gray};
`;
