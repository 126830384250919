const theme = {
  white: "#FFFFFF",
  black: "#2B2B2B",
  gray: "#F5F5F5",
  gray2: "#7A7A7A",
  gray3: "#FAFAFA",
  green: "#73ADA8",
  orange: "#DE7A5A",
  small: "(min-width: 360px)",
  medium: "(min-width: 800px)",
  large: "(min-width: 1200px)",
};
export default theme;
