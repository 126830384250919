import React from 'react'
import * as Nav from './styled';
import HeaderImage from '../headerimage'

const Navigation = ({ image }) => {
    return (
        <Nav.Wrapper image={image ? true : false}>
            <Nav.LogoLink white={image ? true : false} />
            <Nav.Menu image={image}>
                <Nav.PageLink to="/ueber-mich">Über Mich</Nav.PageLink>
                <Nav.PageLink to="/">Produkte</Nav.PageLink>
            </Nav.Menu>
            {image && <HeaderImage image={image} />}
        </Nav.Wrapper>
    )
}

export default Navigation
