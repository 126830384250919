import styled from "styled-components";
import Logo from "./logo";
import { Link } from "gatsby";

type WrapperType = {
  image?: boolean;
};
export const Wrapper = styled.div<WrapperType>`
  position: relative;
  padding: 60px 20px;
  @media ${({ theme }) => theme.medium} {
    padding: 60px 60px;
  }
  height: ${({ image }) => (image ? "480px" : "initial")};
  background: ${({ image, theme }) => (image ? "transparent" : theme.gray)};

  display: flex;
  align-items: ${({ image }) => (image ? "flex-start" : "center")};
`;

export const LogoLink = styled(Logo)`
  width: 100px;
  height: 100px;
`;

export const Menu = styled.div<WrapperType>`
  margin-left: -40px;
  margin-right: -40px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 50px;
  @media ${({ theme }) => theme.medium} {
    padding-left: 100px;
  }
  a {
    color: ${({ theme, image }) => (image ? theme.white : theme.black)};
  }
`;

export const PageLink = styled(Link)`
  padding: 10px 10px;
  @media ${({ theme }) => theme.medium} {
    padding: 20px 40px;
  }
`;
