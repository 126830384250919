import { createGlobalStyle } from "styled-components";
import theme from "./theme";

export default createGlobalStyle`
    html {
        height: 100%;
    }
    body {
        height: 100%;
        margin: 0;
        font-family: 'Poppins', sans-serif;
        color: ${theme.black};
        background-color: ${theme.gray3};
        letter-spacing: 0.2px;
    }
    a {
        color: ${theme.black};
        text-decoration: none;
    }
    p{
        line-height: 1.5rem;
        letter-spacing: 0.2px;
        margin: 0;
        padding: 10px 0;
    }
    h1, h2, h3 {
        font-family: 'Kalam', serif;
        color: ${theme.green};
        font-size: 2rem;
        line-height: 2rem;
        margin: 0;
    }
    @media ${theme.medium}{
        h1, h2, h3 {
            font-size: 3rem;
            line-height: 3rem;
        }
    }
    #___gatsby,#gatsby-focus-wrapper {
        height: 100%;
    }
    html > div {
        box-sizing: border-box;
    }
`;
