import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as Cont from './styled'
import useContact from '../../hooks/useContact'

const Contact = () => {
    const contact = useContact()
    return (
        <React.Fragment>
            { contact &&
                <Cont.Container>
                    {documentToReactComponents(contact)}
                </Cont.Container>
            }
        </React.Fragment>
    )
}

export default Contact
